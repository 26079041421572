import React, { useEffect } from "react";
import SEO from "../components/seo";
import SwiftType from "../components/swift-type";
import { graphql } from "gatsby";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import BEMHelper from "react-bem-helper";

// Components
import Layout from "../components/layout";
import ModuleCtaBanner from "../modules/module-cta-banner";
import moduleConductor from "../modules/module-conductor";

// Logos
import LinkedinLogo from "../blocks/linkedin-logo";
import EmailLogo from "../blocks/email-logo";
import TwitterLogo from "../blocks/twitter-logo";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

// Helpers, Functions
import { richTextConfig } from "../rich-text-config/rich-text-config";

const bem = new BEMHelper({
  name: "leadership-bio-page",
});

const LeadershipBioPage = ({ data, location, pageContext }) => {
  const {
    id,
    fullName,
    slug,
    seoPageTitle,
    firstName,
    lastName,
    jobTitle,
    partOf,
    picture,
    linkedin,
    email,
    twitter,
    biography,
    sidebarCallout,
    socialShare,
  } = data.contentfulTemplateLeadership;
  const metaDesc =
    JSON.parse(biography.raw).content
      .filter((node) => node.nodeType === "paragraph")[0]
      .content[0].value.substring(0, 220) + "...";
  const ctaBanner = data.ctaBanner ? data.ctaBanner.nodes[0] : null;
  const seoDesc = socialShare?.description;
  const seoImage = socialShare?.file?.url;
  const metaTitle = seoPageTitle || `${firstName + " " + lastName} | UnitedLex`;
  useEffect(() => {
    // document.getElementById("loader_wrap").classList.add("d-none");
    // document.getElementById("loader_wrap").classList.add("loaded");
  }, []);

  const Loader = () => {
    let loaderDiv = document.getElementById("loader");
    let delay = 0.004;

    for (let x = 0; x < 40; x++) {
      let width = x * 2.5 + "%";
      let div = document.createElement("div");
      loaderDiv.appendChild(div);
      div.style.left = width;
      div.style.top = 0;
      div.style.animationDelay = ((x - 20) * (x - 20) * delay) / 8 + "s";
    }
  };

  return (
    <Layout location={location}>
      <SEO
        title={metaTitle}
        url={location?.href}
        description={seoDesc || metaDesc}
        image={seoImage || picture?.file?.url}
      />
      <SwiftType
        customTitle={firstName + " " + lastName}
        customFeatImg={picture?.file?.url}
      />
      <div {...bem()}>
        <header {...bem("header")}>
          {picture && (
            <GatsbyImage
              {...bem("header-image")}
              image={getImage(picture.gatsbyImageData)}
              style={{aspectRatio: "1/1"}}
              alt={picture.description}
              title={picture.description}
            />
          )}
          <address {...bem("personal-info")}>
            {partOf && <span {...bem("part-of")}>{partOf}</span>}
            <h1 {...bem("name")}>{`${firstName} ${lastName}`}</h1>
            {jobTitle && (
              <div {...bem("job-title")}>
                {documentToReactComponents(JSON.parse((jobTitle.raw)), richTextConfig())}
              </div>
            )}
            <ul {...bem("contacts")}>
              {linkedin && (
                <li {...bem("contact-list-item")}>
                  <a
                    {...bem("contact-link")}
                    href={linkedin}
                    target="_blank"
                    title="linkedin"
                  >
                    <LinkedinLogo />
                  </a>
                </li>
              )}
              {email && (
                <li {...bem("contact-list-item")}>
                  <a
                    {...bem("contact-link")}
                    href={`mailto:${email}`}
                    target="_blank"
                    title="send an email"
                  >
                    <EmailLogo />
                  </a>
                </li>
              )}
              {twitter && (
                <li {...bem("contact-list-item")}>
                  <a
                    {...bem("contact-link")}
                    href={twitter}
                    target="_blank"
                    title="twitter"
                  >
                    <TwitterLogo />
                  </a>
                </li>
              )}
            </ul>
          </address>
        </header>
        <div {...bem("main-content-wrapper")}>
          <aside {...bem("aside")}>
            {sidebarCallout && (
              <div {...bem("aside-content")}>
                {documentToReactComponents(
                  JSON.parse(sidebarCallout.raw),
                  richTextConfig()
                )}
              </div>
            )}
          </aside>
          <article {...bem("body")}>
            <div {...bem("body-copy-wrapper")}>
              {biography &&
                documentToReactComponents(JSON.parse(biography.raw), richTextConfig())}
            </div>
          </article>
        </div>
        {ctaBanner && moduleConductor(ctaBanner, 0)}
        {/* <ModuleCtaBanner
          cta1Headline="Lets Get Started"
          cta1Description={{
            cta1Description:
              "We work with forward-thinking corporate and law firm leaders to thrive in the digital age.",
          }}
          cta1Link={{ ctaLinkText: "Let’s connect" }}
        /> */}
      </div>
    </Layout>
  );
};

export default LeadershipBioPage;

export const pageQuery = graphql`
  query($slug: String!, $ctaBannerId: String!) {
    contentfulTemplateLeadership(slug: { eq: $slug }) {
      id
      fullName
      slug
      seoPageTitle
      doNotIndex
      firstName
      lastName
      jobTitle {
        raw
      }
      partOf
      picture {
        id
        title
        description
        gatsbyImageData(width: 800, formats: JPG, quality: 75)
        # fluid(maxWidth: 800, toFormat: JPG, quality: 75) {
        #  ...GatsbyContentfulFluid
        # }
        file {
          url
        }
      }
      linkedin
      email
      twitter
      biography {
        raw
      }
      sidebarCallout {
        raw
      }
      socialShare {
        description
        title
        file {
          url
        }
      }
    }
    ctaBanner: allContentfulModuleCtaBanner(
      filter: { contentful_id: { eq: $ctaBannerId } }
    ) {
      nodes {
        ...ModuleCtaBannerFragment
      }
    }
  }
`;
